body {
    background-color: rgb(234, 234, 234) !important;
}

/*******************************************/
/*keyframes*/
/*******************************************/
@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideOutToLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeFromAlmostIn {
    0% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeAlmostOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.4;
    }
}

@keyframes rotateIn {
    0% {
        opacity: 0;
        transform: rotateZ(-30deg);
    }

    100% {
        opacity: 1;
        transform: rotateZ(0);
    }
}

@keyframes rotateOutAndClose {
    0% {
        opacity: 1;
        transform: rotateZ(0);
    }

    100% {
        opacity: 0;
        transform: rotateZ(30deg);
        display: none !important;
    }
}

@keyframes horizontalFlipIn {
    0% {
        opacity: 0;
        transform: rotateY(-90deg);
    }
    100% {
        opacity: 1;
        transform: rotateY(0deg);
    }
}

@keyframes horizontalFlipOut {
    0% {
        opacity: 1;
        transform: rotateY(0deg);
    }
    100% {
        opacity: 0;
        transform: rotateY(90deg);
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        margin-top: -100px;
    }
    100% {
        opacity: 1;
        margin-top: 0;
    }
}

@keyframes slideUp {
    0% {
        opacity: 1;
        margin-top: 0;
    }
    100% {
        opacity: 0;
        margin-top: -100px;
    }
}

/*******************************************/
/*timepicker style*/
/*******************************************/
.react-datepicker__time-container,
.react-datepicker__time,
.react-datepicker__time-box,
ul.react-datepicker__time-list {
    padding-left: 0;
    padding-right: 0;
    width: 100px;
}

.react-datepicker__input-container {
    width: inherit;
}

.react-datepicker-wrapper {
    width: 100%;
}

@media (max-width: 768px) {
    .react-datepicker-popper {
        margin-left: -40px !important;
    }

    .react-datepicker__portal {
        margin-left: -80px !important;
        /* left:0 !important; */
        width: 120vw !important;
    }
}

/*******************************************/
/*home page styles*/
/*******************************************/
.homepage {
    display: flex;
    background-image: linear-gradient(
            135deg,
            #003366 0%,
            white 89%
    ) !important;
}

@media (min-width: 768px) {
    .homepage {
        align-items: center;
        height: 100% !important;
    }
}

@media (min-width: 1200px) {
    .homepage {
        height: 100vh !important;
    }
}

.homepage .container {
    max-width: 1400px !important;
}

.home-paragraph {
    height: 400px;
    overflow-y: auto;
    text-align: justify;
}

/*******************************************/
/* navbar styles */
/*******************************************/
.ui.fixed.menu {
    background-image: linear-gradient(
            135deg,
            #003366 0%,
            #4d4d4f 89%
    ) !important;
    height: 50px !important;
    z-index: 900;
}

.ui.main.container {
    padding-top: 60px;
}

.fluid.container {
    max-width: 1160px !important;
}

.ui.center.aligned.segment.attendance-preview {
    background-color: #f5f5f5;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
    margin-left: 0.5em;
}

.ui.menu .item > img:not(.ui) {
    margin-right: 1.5em !important;
}

.ui.menu:not(.vertical) .item > .button {
    margin-left: 0.5em;
}

/*******************************************/
/*Style overrides*/
/*******************************************/
.ui.modal > .header {
    font-size: 18px !important;
    background: #003366;
    padding: 0.75rem 1.5rem;
    color: rgba(255, 255, 255, 0.85);
}

.ui.modal > .actions {
    padding: 0.6rem 1rem;
}

.ui.modal > .close {
    top: 0.2rem;
    right: 0rem;
}

.ui.segment,
.ui.segments,
.ui.menu {
    /*box-shadow: 5px 5px 5px rgb(150,150,150);*/
}

.ui.dimmer {
    z-index: 890;
}

.ui.modals.dimmer {
    z-index: 990;
}

/*******************************************/
/* dropzone styles */
/*******************************************/
.dropzone {
    border: solid 1px black;
    border-radius: 5px;
    padding: 5px;
    padding-right: 2px;
    text-align: center;
    cursor: pointer;
}

.dropzone--isActive {
    border: dashed 3px green;
}

/*******************************************/
/* own styles */
/*******************************************/
.segment-transparent {
    background-color: transparent !important;
    border: none !important;
    box-shadow: 0 0 0 0 transparent !important;
}

.form > .actions {
    background: #f9fafb;
    padding: 0.6rem 1rem;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    text-align: right;
    bottom: 0;
}

.form-element .selection,
.form-element textarea {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.element-focus .label {
    background-color: #8a8c8f !important;
    color: white
}

.element-focus input,
.element-focus textarea,
.element-focus .selection {
    border-color: #8a8c8f !important;

}

.element-error .label {
    background-color: rgba(255, 150, 150, 1.0) !important;
    color: white;
    border-bottom-left-radius: 0 !important;
}

.element-error input,
.element-error textarea,
.element-error .selection {
    border-color: rgba(224, 180, 180) !important;
    border-bottom-right-radius: 0 !important;
    background-color: rgb(255, 246, 246) !important;
}

/* .error-label,
.error-label-select {
  margin-left: 0 !important;
  color: red !important;
}

.error-label {
  border: 1px solid red !important;
  border-top: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.error-label-select {
  border: 0 !important;
} */

.login-section {
    height: 300px;
    overflow: hidden;
}

.login-section > div {
    animation: 600ms ease-out 0s 1 slideInFromLeft;
}

.header-actions {
    margin-top: -6px !important;
}

@media (max-width: 768px) {
    .header-actions {
        margin-top: -6px !important;
        opacity: 0.4;
        animation: 600ms ease-out 0s 1 fadeAlmostOut;
    }

    .header-actions-focus {
        opacity: 1 !important;
        animation: 600ms ease-out 0s 1 fadeFromAlmostIn;
    }
}

@media (min-width: 768px) {
    .header-actions {
        margin-top: -6px !important;
        opacity: 0;
        animation: 600ms ease-out 0s 1 fadeOut;
    }

    .header-actions-focus {
        opacity: 1 !important;
        animation: 600ms ease-out 0s 1 fadeIn;
    }
}

/*******************************************/
/* Modal Styles*/
/*******************************************/
.modal-animation-open {
    animation: 500ms none 0s 1 fadeIn;
}

.modal-animation-closing {
    animation: 500ms none 0s 1 fadeOut;
    opacity: 0;
}

.functionmodal-horizontalflip {
    animation: 300ms ease-out 0s 1 slideDown;
}

.functionmodal-horizontalflip-closing {
    animation: 300ms ease-in 0s 1 slideUp;
    opacity: 0;
}

/*******************************************/
/* Function Modal Styles*/
/*******************************************/
.functionmodal-container {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(150, 150, 150, 0.7);
    top: 0;
    left: 0;
    z-index: 999998;
    vertical-align: middle;
}

.functionmodal {
    width: 360px;
    max-width: 100%;
    min-height: 100px;
    left: calc(50% - 180px);
    top: calc(50% - 120px);
    background-color: white;
    margin: auto auto;
    position: absolute;
    /* animation: 600ms ease-out 0s 1 fadeIn; */
    border-radius: 4px;
    padding: 10px;
    z-index: 999999;
    position: fixed;
}

@media (max-width: 360px) {
    .functionmodal {
        left: 0;
    }
}

/*******************************/
/* Colour scheme */
/*******************************/
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.byy-text-peach {
    color: #fcd4c2 !important;
    opacity: 0.8 !important;
}

.byy-text-lighterblue {
    color: #c2e5ed !important;
    opacity: 0.8 !important;
}

.byy-text-pink {
    color: #ff66c4 !important;
}

.byy-text-darkpink {
    color: #e81f78 !important;
}

.byy-text-red {
    color: #e01c21 !important;
}

.byy-text-lightblue {
    color: #38b6ff !important;
}

.byy-text-cyan {
    color: #00b2e3 !important;
}

.byy-text-darkblue {
    color: #003366 !important;
}

.byy-text-gold {
    color: #ff9e1a !important;
}

.byy-text-grey {
    color: #7d7d7d !important;
}

.byy-text-lightgrey {
    color: #8a8c8f !important;
}

.byy-text-charcoal {
    color: #4d4d4f !important;
}

.byy-teal {
	background-color: #0cb2a6 !important;
	opacity: 0.8 !important;
}

.byy-peach {
    background-color: #fcd4c2 !important;
    opacity: 0.8 !important;
}

.byy-lighterblue {
    background-color: #c2e5ed !important;
    opacity: 0.8 !important;
}

.byy-pink {
    background-color: #ff66c4;
}

.byy-darkpink {
    background-color: #e81f78;
}

.byy-red {
    background-color: #e01c21;
}

.byy-lightblue {
    background-color: #38b6ff;
}

.byy-cyan {
    background-color: #00b2e3;
}

.byy-darkblue {
    background-color: #003366;
}

.byy-gold {
    background-color: #ff9e1a;
}

.byy-grey {
    background-color: #7d7d7d;
}

.byy-lightgrey {
    background-color: #8a8c8f;
}

.byy-charcoal {
    background-color: #4d4d4f;
}


.byy-bg-pink {
    background: #ff66c4;
}

.byy-bg-darkpink {
    background: #e81f78;
}

.byy-bg-red {
    background: #e01c21;
}

.byy-bg-lightblue {
    background: #38b6ff;
}

.byy-bg-cyan {
    background: #00b2e3;
}

.byy-bg-darkblue {
    background: #003366;
}

.byy-bg-gold {
    background: #ff9e1a;
}

.byy-bg-grey {
    background: #7d7d7d;
}

.byy-bg-lightgrey {
    background: #8a8c8f;
}

.byy-bg-charcoal {
    background: #4d4d4f;
}

/*******************************/
/* End Colour scheme */
/*******************************/
.App {
  text-align: center;
  background-image: linear-gradient(
          135deg,
          #003366 0%,
          #4d4d4f 89%
  ) !important;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-image: linear-gradient(
    135deg,
    #003366 0%,
    #00b2e3 69%,
    #38b6ff 89%
  ) !important; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.edit-user-image-container {
    padding: '20px';
    height: '220px';
    text-align: center;
}

.edit-user-image-container :hover {
    opacity: 1;
}

.edit-user-image {
    position: absolute;
    top: 70px;
    left: 70px;
    width: 40px;
    opacity: 0.2;
}
